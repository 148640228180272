<template>
  <CContainer>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
            <strong>Editar Rol</strong>
          </CCardHeader>
          <CCardBody>
            <CForm validate>
               <CRow>
                   <CCol col="6">
                      <CInput
                          label="Rol"
                          minlength="3"
                          required
                          was-validated
                          v-model="rol.name"
                        />
                   </CCol>
                   <CCol col="6">
                     <CInput
                        label="Url Base Rol"
                        minlength="3"
                        required
                        was-validated
                        v-model="rol.url"
                      />
                   </CCol>
               </CRow>
            </CForm>
          </CCardBody>
        <!--<CCardFooter>
          
        </CCardFooter>-->
      </CCard>
    </CCol>
  </CRow>
  <CRow>
    <CCol col="12">
       <CCard>
        <CCardHeader>
           <CRow class="justify-content-between">
              <CCol col="3">
                  Permisos
              </CCol>
              <CCol col="3">
                  <CButton color="primary" @click="actualizarRolPermisos" v-if="$can('rol.edit')">Actualizar</CButton>
                  <CButton color="primary" @click="goBack">Atras</CButton>
              </CCol>
           </CRow>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            hover
            striped
            :items="permisos"
            :fields="fields"
            :items-per-page="10"
            :pagination="{ doubleArrows: false, align: 'center'}"
            column-filter
            
          >
           <template #permisos="data">
             <tr class="d-flex justify-content-around">
              <td>
                <label for="">Ver {{data.item.description}}</label>
                <input type="checkbox" :id="data.item.name+'_'+data.item.id" :value="data.item.id" v-model="permisosChecked" @change="checkHijosPermiso($event,data.item.hijos,data.item.id)">
              </td>
               <template v-for="(item_hijos, index1) in data.item.hijos" :key="index1">
                 <td>
                   <label for="">{{item_hijos.description}}</label>
                   <input type="checkbox" :id="item_hijos.name+'_'+data.item.id" :value="item_hijos.id" v-model="permisosChecked" @change="checkPadrePermiso($event,data.item.id)">
                 </td>
               </template>
             </tr>
               
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
         <div>
          <CToaster :autohide="5000" class="d-inline-block">
             <template v-for="(toast, index) in mostrarNotificacion" :key="'toast'+index">
                <CToast
                  :key="'toast'+index"
                  :show="true"
                  color="success"
                  header="Notificacion Exitosa"
                >
                  Datos Guardado Correctamente.
                </CToast>
              </template>   
              <template v-for="(toast1, index1) in mostrarNotificacionAlerta" :key="'toast1'+index1">
                <CToast
                  :key="'toast1'+index1"
                  :show="true"
                  color="warning"
                  header="Notificacion Alerta"
                >
                  {{mensaje_warning}}
                </CToast>
              </template>
              <template v-for="(toast2, index2) in mostrarNotificacionError" :key="'toast2'+index2">
                <CToast
                  :key="'toast2'+index2"
                  :show="true"
                  color="danger"
                  header="Notificacion Alerta"
                >
                  {{mensaje_error}}
                </CToast>
              </template>
          </CToaster>
        </div>
  </CContainer>
</template>

<script>
export default {
  name: 'EditarRoles',
  data () {
    return {
      rol:{},
      permisos:[],
      permisosChecked:[],
      fields: [
        { key: 'description', label: 'Modulo', _classes: 'font-weight-bold' },
        { key: 'permisos',label:'Permisos Por Modulo',sorter: false, filter: false,_classes:'text-center'},
        /*{ key: 'todos',label:'Todos',sorter: false, filter: false  },
        { key: 'ver',label:'Ver', sorter: false, filter: false  },
        { key: 'crear',label:'Crear', sorter: false, filter: false  },
        { key: 'editar',label:'Editar', sorter: false, filter: false  },*/
      ],
      activePage: 1,
      mostrarNotificacion: 0,
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError:0,
      mensaje_warning:'Debe Completar Los Datos Vacio',
      mensaje_error:'Error Comunicarse Con Soporte'
    }
  },
  computed: {
    
  },
  methods: {
    goBack() {
      this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/roles'})
    },
    obtenerRolPorId () {
        const id = this.$route.params.id
        this.$store.dispatch('auth/loading_http','visible');
        this.$http.get('/sms/roles/'+id).then(response => {
            let data = response.data;
            this.rol = data.rol;
            for (const iterator of data.permisos_rol) {
                this.permisosChecked.push(iterator.id);
            }
        }).catch((e)=>{});
        this.$store.dispatch('auth/loading_http','visible');
        this.$http.get('/sms/roles/create').then(response => {
            let data = response.data;
            this.permisos = data;
        }).catch((e)=>{}).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    validator (val) {
      return val ? val.length >= 4 : false
    },
    actualizarRolPermisos(){
       let name = this.rol.name;
       let url = this.rol.url;
        name = name.trim();
        url = url.trim();
        if (name ==  "" || url == "") {
          this.mensaje_warning = 'Debe Completar Los Datos Vacio'
          this.mostrarNotificacionAlerta += 1;
        }else{
          const id = this.$route.params.id
          this.$store.dispatch('auth/loading_http','visible');
          this.$http.put('/sms/roles/'+id,{name:this.rol.name,url:this.rol.url,array_permisos:this.permisosChecked})
          .then(response => {
            this.mostrarNotificacion += 1;
          }).catch((error)=>{
            let mensajeError = '';
            if (error.response) {
              let dataError = error.response.data;
               if (error.response.status == 422) {
                   mensajeError = `${dataError.message} \n`;
                   let errors = dataError.errors;
                   for (const key in errors) {
                     if (Object.hasOwnProperty.call(errors, key)) {
                       const element = errors[key];
                       for (const iterator of element) {
                          mensajeError += `${iterator} \n`
                       }
                     }
                   }
                   this.mensaje_warning = mensajeError
                   this.mostrarNotificacionAlerta += 1;
               }else{
                  this.mostrarNotificacionError += 1;
               }
            }
          }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
      }
    },
    checkPadrePermiso(e,padre_id){
      let existe = this.permisosChecked.find(p=> p == padre_id);
      if (existe == undefined && e.target.checked) {
        this.permisosChecked.push(padre_id); 
      }
    },
    checkHijosPermiso(e,hijos,padre_id){
      let existe = this.permisosChecked.find(p=> p == padre_id);
      if (existe == undefined && e.target.checked == false) {
         for (const key in hijos) {
           if (Object.hasOwnProperty.call(hijos, key)) {
             const element = hijos[key];
             const index = this.permisosChecked.indexOf(element.id);
              if (index > -1) {
                  this.permisosChecked.splice(index, 1);
              }
           }
         }
      }
    }
  },
  mounted () {
    this.obtenerRolPorId();
    /*this.$router.push({path: '/roles'})
      setTimeout(() => {
        this.obtenerRolPorId();
      }, 200);*/
  },
}
</script>
